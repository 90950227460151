import React from 'react'
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
  ReferenceLine,
} from 'recharts'
import { AnalysisHistoricalResults, ScoreEntry, ScoreData } from '../../types'
import usePadding from '../../hooks/useChartBarsPositions'
import { formatDate, getBarColor } from '../../utils/chartUtils'
import { useTranslation } from 'react-i18next'
import RenderCustomLegend from '../RenderCustomLegend'

interface EvolutionBarChartProps {
  data: AnalysisHistoricalResults | null
  scoreKey: string
  lowLabel?: string
  mediumLabel?: string
  highLabel?: string
  yAxisLabels: string[]
}

const EvolutionBarChart: React.FC<EvolutionBarChartProps> = ({
  data,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
}) => {
  const scoreData = data?.data?.attributes?.scores
    ?.find((s: ScoreData) => s.name === scoreKey)
    ?.data.map((item: ScoreEntry) => ({
      date: item.date,
      score: item.score,
      rating: Number(item.rating),
    }))
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  const padding = usePadding()
  const { t } = useTranslation()

  let minScore: number
  let maxScore: number

  switch (scoreKey) {
    case 'IPAQ':
      minScore = 0
      maxScore = 3000
      break
    case 'MEDAS':
      minScore = 0
      maxScore = 14
      break

    default:
      minScore = 0
      maxScore = 100
  }

  const validData = scoreData ? scoreData.slice(-3) : []

  if (!validData.length) {
    console.warn(`${scoreKey} no está disponible o no tiene datos.`)
  }

  const ticks = [minScore, (minScore + maxScore) / 2, maxScore]

  return (
    <div className="w-[100%] sm:w-[60%]">
      <ResponsiveContainer height={350}>
        <BarChart
          data={validData}
          margin={{ top: 20, right: 20, bottom: 20, left: 40 }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={formatDate}
            scale="point"
            padding={padding}
            axisLine={true}
            tickLine={true}
            tick={{ fontSize: 16 }}
          />

          <YAxis
            domain={[minScore, maxScore]}
            ticks={ticks}
            tickFormatter={(value) => {
              if (scoreKey === 'MEDAS') {
                switch (value) {
                  case minScore:
                    return t(
                      'myEvolution.nutritional.chart.medasChart.legend.low',
                    )
                  case (minScore + maxScore) / 2:
                    return t(
                      'myEvolution.nutritional.chart.medasChart.legend.medium',
                    )
                  case maxScore:
                    return t(
                      'myEvolution.nutritional.chart.medasChart.legend.high',
                    )
                  default:
                    return value
                }
              } else if (scoreKey === 'IPAQ') {
                switch (value) {
                  case minScore:
                    return t(
                      'myEvolution.physicalActivity.chart.yAxisLabels',
                    )[0]
                  case (minScore + maxScore) / 2:
                    return t(
                      'myEvolution.physicalActivity.chart.yAxisLabels',
                    )[1]
                  case maxScore:
                    return t(
                      'myEvolution.physicalActivity.chart.yAxisLabels',
                    )[2]
                  default:
                    return value
                }
              }
              return yAxisLabels?.[value] || value
            }}
            interval={0}
            tickCount={3}
            width={70}
            axisLine={true}
            tickLine={true}
            tick={{ fontSize: 16 }}
          />

          <ReferenceLine y={maxScore / 2} stroke="#666" strokeDasharray="3 3" />

          <Tooltip
            formatter={(value) => `${value} pts`}
            labelFormatter={formatDate}
          />
          <Legend
            content={
              <RenderCustomLegend
                lowLabel={lowLabel || ''}
                mediumLabel={mediumLabel || ''}
                highLabel={highLabel || ''}
              />
            }
          />

          <Bar
            dataKey="score"
            barSize={40}
            fillOpacity={1}
            stroke="none"
            name="Puntuación"
          >
            {validData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getBarColor(entry)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EvolutionBarChart
