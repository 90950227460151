export enum FontSizeName {
  normal = 'normal',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export enum FontStyle {
  regular,
  medium,
}
