import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '../input/input'

import { WarningFilled } from '@carbon/icons-react'

export const InputPassword = ({ className = '', errors = {}, ...rest }) => {
  const [visiblePassword, setVisiblePassword] = useState(false)
  const errorClasses =
    errors[rest.name] || rest.mainError
      ? 'border-error bg-secondary-10 focus-visible:outline-error text-error'
      : ''
  const { t } = useTranslation()

  const errorMessage = errors[rest.name]?.message
  const textErrorMessageClasses = errorMessage
    ? 'text-error'
    : 'text-mediumGray'

  return (
    <div className="flex flex-col gap-1 pb-2 relative text-[16px]">
      <Input
        {...rest}
        type={visiblePassword ? 'text' : 'password'}
        className={`h-11 w-full px-2 border rounded border-px ${errorClasses}`}
      />

      <div className="flex gap-2 items-baseline mt-0 text-[16px]">
        <Input
          className="self-baseline accent-[#004039]"
          type="checkbox"
          id="showPassword"
          name="showPassword"
          onChange={() => setVisiblePassword(!visiblePassword)}
        />
        <label htmlFor="inputTextshowPassword">
          {t('auth.newPassword.showPassword')}
        </label>
      </div>
      {errors && errors[rest.name] && (
        <p
          className={`flex gap-2 mt-0 items-center ${textErrorMessageClasses}`}
        >
          <WarningFilled size={16} />
          {errors && errorMessage ? t(errorMessage) : t('auth.signUp.dni')}
        </p>
      )}
    </div>
  )
}
