import React, { useState } from 'react'
import { DescriptionModal } from './DescriptionModal'
import { AnalysisHistoricalResults } from '../types'
import { useTranslation } from 'react-i18next'
import NoDataMessage from './NoDataMessage'
import NutritionalCharts from '../components/NutritionalCharts'
import IPAQCharts from './IPAQCharts'
import EmotionalCharts from './EmotionalCharts'
import TobaccoAndAlcoholCharts from './TobaccoAndAlcoholCharts'
import DSTitle from 'libs/shared/ui/src/lib/components/generics/ds-title'
import BodyText from 'libs/shared/ui/src/lib/components/generics/body-text'
interface ResultsDescriptionProps {
  data?: AnalysisHistoricalResults | null
  scoreKey?: string
  description?: string
  title?: string
  title2?: string
  title3?: string
  modalLink?: string
  chartTitle?: string
  chartDescription?: string
  chartDescription2?: string
  modalDescription?: string
  modalDescription2?: string
  modalDescription3?: string
  modalTitle?: string
  modalTitle2?: string
  modalTitle3?: string
  modalButton?: string
  modalButton2?: string
  modalButton3?: string
  loading?: boolean
  error?: unknown
  loadingText?: string
  lowLabel?: string
  mediumLabel?: string
  highLabel?: string
  yAxisLabels?: string[]
  imcLegendLow?: string
  imcLegendMedium?: string
  imcLegendHigh?: string
  imcChartDescription?: string
  noGraphic?: string
  scoreKey2?: string
  scoreKey3?: string
  chartDescription3?: string
}

const ResultsDescription: React.FC<ResultsDescriptionProps> = ({
  data,
  scoreKey,
  scoreKey2,
  scoreKey3,
  modalButton,
  title,
  title2,
  title3,
  modalLink,
  chartTitle,
  chartDescription,
  chartDescription2,
  chartDescription3,
  modalDescription,
  modalTitle,
  modalDescription2,
  modalTitle2,
  modalDescription3,
  modalTitle3,
  loading,
  error,
  loadingText,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  imcLegendLow,
  imcLegendMedium,
  imcLegendHigh,
  imcChartDescription,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [isModalOpen3, setIsModalOpen3] = useState(false)
  const { t } = useTranslation()

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen)
  }
  const handleModalToggle2 = () => {
    setIsModalOpen2(!isModalOpen2)
  }
  const handleModalToggle3 = () => {
    setIsModalOpen3(!isModalOpen3)
  }
  const noDataMessage =
    !data ||
    Object.keys(data.data.attributes).length === 0 ||
    (Array.isArray(data.data.attributes[scoreKey || '']) &&
      data.data.attributes[scoreKey || ''].length === 0)

  const noScoresInChart = (scoreKey: string) => {
    const scores = data?.data?.attributes?.scores
    return scores
      ?.find((score: any) => score.name === scoreKey)
      ?.data.some(
        (item: any) => isNaN(item.score) || typeof item.score === 'string',
      )
  }

  return (
    <>
      <DSTitle className="flex font-semibold mb-4 mt-8 font-aeonik text-generic-neutral-900">
        {t('myEvolution.chartDescriptionTitle')}
      </DSTitle>
      {loading ? (
        <BodyText className="animate-pulse flex justify-center items-center h-full">
          {loadingText}
        </BodyText>
      ) : noDataMessage ? (
        <NoDataMessage noGraphic={!scoreKey} />
      ) : (
        <>
          {scoreKey === 'MEDAS' ? (
            <NutritionalCharts
              handleModalToggle2={handleModalToggle2}
              imcTitle={title2 || ''}
              modalLink={modalLink || ''}
              imcChartDescription={imcChartDescription || ''}
              error={error}
              data={data}
              imcLegendLow={imcLegendLow || ''}
              imcLegendMedium={imcLegendMedium || ''}
              imcLegendHigh={imcLegendHigh || ''}
              scoreKey={scoreKey || ''}
              lowLabel={lowLabel || ''}
              mediumLabel={mediumLabel || ''}
              highLabel={highLabel || ''}
              yAxisLabels={yAxisLabels || []}
              title={title || ''}
              chartTitle={chartTitle || ''}
              chartDescription={chartDescription || ''}
              handleModalToggle={handleModalToggle}
              noScoresInChart={noScoresInChart('IMC') || false}
              noScoresInChart2={noScoresInChart('MEDAS') || false}
            />
          ) : null}
          {scoreKey === 'IPAQ' ? (
            <IPAQCharts
              handleModalToggle={handleModalToggle}
              title={title || ''}
              modalLink={modalLink || ''}
              chartTitle={chartTitle || ''}
              chartDescription={chartDescription || ''}
              error={error}
              data={data}
              scoreKey={scoreKey || ''}
              lowLabel={lowLabel || ''}
              mediumLabel={mediumLabel || ''}
              highLabel={highLabel || ''}
              yAxisLabels={yAxisLabels || []}
              noScoresInChart={noScoresInChart('IPAQ') || false}
            />
          ) : null}
          {(scoreKey === 'GOLDBERG_ANXIETY' ||
            scoreKey === 'GOLDBERG_DEPRESSION') && (
            <EmotionalCharts
              handleModalToggle={handleModalToggle}
              modalLink={modalLink || ''}
              error={error}
              data={data}
              scoreKey={scoreKey || ''}
              scoreKey2={scoreKey2 || ''}
              lowLabel={lowLabel || ''}
              mediumLabel={mediumLabel || ''}
              highLabel={highLabel || ''}
              yAxisLabels={yAxisLabels || []}
              title={title || ''}
              chartTitle={chartTitle || ''}
              chartDescription={chartDescription || ''}
              chartDescription2={chartDescription2 || ''}
              noScoresInChart={noScoresInChart('GOLDBERG_DEPRESSION') || false}
              noScoresInChart2={noScoresInChart('GOLDBERG_ANXIETY') || false}
            />
          )}
          {(scoreKey === 'FAGERSTRON' ||
            scoreKey2 === 'RICHMOND' ||
            scoreKey3 === 'AUDIT_C') && (
            <TobaccoAndAlcoholCharts
              handleModalToggle={handleModalToggle}
              handleModalToggle2={handleModalToggle2}
              handleModalToggle3={handleModalToggle3}
              modalLink={modalLink || ''}
              error={error}
              data={data}
              scoreKey={scoreKey || ''}
              scoreKey2={scoreKey2 || ''}
              scoreKey3={scoreKey3 || ''}
              lowLabel={lowLabel || ''}
              mediumLabel={mediumLabel || ''}
              highLabel={highLabel || ''}
              yAxisLabels={yAxisLabels || []}
              title={title || ''}
              title2={title2 || ''}
              title3={title3 || ''}
              chartTitle={chartTitle || ''}
              chartDescription={chartDescription || ''}
              chartDescription2={chartDescription2 || ''}
              chartDescription3={chartDescription3 || ''}
              noScoresInChart={noScoresInChart('FAGERSTRON') || false}
              noScoresInChart2={noScoresInChart('RICHMOND') || false}
              noScoresInChart3={noScoresInChart('AUDIT_C') || false}
            />
          )}
        </>
      )}
      {isModalOpen && (
        <DescriptionModal
          onClose={handleModalToggle}
          description={modalDescription || ''}
          title={modalTitle || ''}
          button={modalButton || ''}
        />
      )}
      {isModalOpen2 && (
        <DescriptionModal
          onClose={handleModalToggle2}
          description={modalDescription2 || ''}
          title={modalTitle2 || ''}
          button={modalButton || ''}
        />
      )}
      {isModalOpen3 && (
        <DescriptionModal
          onClose={handleModalToggle3}
          description={modalDescription3 || ''}
          title={modalTitle3 || ''}
          button={modalButton || ''}
        />
      )}
    </>
  )
}

export default ResultsDescription
