import { t } from 'i18next'
import EvolutionIMCBarChart from './Charts/EvolutionIMCBarChart'
import EvolutionBarChart from './Charts/EvolutionBarChart'
import BodyText from 'libs/shared/ui/src/lib/components/generics/body-text'
import DSTitle from 'libs/shared/ui/src/lib/components/generics/ds-title'

interface NutritionalChartsProps {
  handleModalToggle2: () => void
  handleModalToggle: () => void
  imcTitle: string
  modalLink: string
  imcChartDescription: string
  error: unknown
  data: any
  imcLegendLow: string
  imcLegendMedium: string
  imcLegendHigh: string
  scoreKey: string
  lowLabel: string
  mediumLabel: string
  highLabel: string
  yAxisLabels: string[]
  title: string
  chartTitle: string
  chartDescription: string
  noScoresInChart: boolean
  noScoresInChart2: boolean
}

const nutritionalCharts = ({
  handleModalToggle2,
  handleModalToggle,
  imcTitle,
  modalLink,
  imcChartDescription,
  error,
  data,
  imcLegendLow,
  imcLegendMedium,
  imcLegendHigh,
  title,
  chartTitle,
  chartDescription,
  scoreKey,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  noScoresInChart,
  noScoresInChart2,
}: NutritionalChartsProps) => {
  return (
    <div className="flex flex-col gap-8">
      {noScoresInChart ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[638px] font-aeonik text-generic-neutral-900">
          <DSTitle className="mb-4 text-[#333333]">{imcTitle}</DSTitle>
          <BodyText
            className="underline mb-4 cursor-pointer"
            onClick={handleModalToggle2}
          >
            {modalLink}
          </BodyText>
          <DSTitle className="my-4">{chartTitle}</DSTitle>
          <BodyText className="mb-5">{imcChartDescription}</BodyText>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <div className="flex flex-col">
              <EvolutionIMCBarChart
                data={data}
                imcLegendLow={imcLegendLow || ''}
                imcLegendMedium={imcLegendMedium || ''}
                imcLegendHigh={imcLegendHigh || ''}
              />
            </div>
          )}
        </div>
      )}
      {noScoresInChart2 ? null : (
        <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[622px] font-aeonik text-generic-neutral-900">
          <DSTitle className="mb-4 text-[#333333]">{title}</DSTitle>
          <BodyText
            className="underline mb-4 cursor-pointer"
            onClick={handleModalToggle}
          >
            {modalLink}
          </BodyText>
          <DSTitle className="mb-4">{chartTitle}</DSTitle>
          <BodyText className="mb-5">{chartDescription}</BodyText>
          {error ? (
            <div className="flex justify-center items-center h-full text-center">
              <p>
                {t('myEvolution.resultsDescription.fetchError1')} <br />
                {t('myEvolution.resultsDescription.fetchError2')}
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              <EvolutionBarChart
                data={data}
                scoreKey={scoreKey}
                lowLabel={lowLabel}
                mediumLabel={mediumLabel}
                highLabel={highLabel}
                yAxisLabels={yAxisLabels || []}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default nutritionalCharts
