import BodyText from 'libs/shared/ui/src/lib/components/generics/body-text'

const RenderCustomLegend = ({
  lowLabel,
  mediumLabel,
  highLabel,
}: {
  lowLabel: string
  mediumLabel: string
  highLabel: string
}) => {
  return (
    <div className="mt-4">
      <ul className="flex flex-col gap-1 list-none p-0 font-aeonik text-generic-neutral-900">
        <li className="flex items-center">
          <div className="w-4 h-4 bg-error mr-4 rounded-sm" />
          <p className="text-[16px]">{lowLabel}</p>
        </li>

        <li className="flex items-center">
          <div className="w-4 h-4 bg-warning mr-4 rounded-sm" />
          <p className="text-[16px]">{mediumLabel}</p>
        </li>

        <li className="flex items-center">
          <div className="w-4 h-4 bg-success mr-4 rounded-sm" />
          <p className="text-[16px]">{highLabel}</p>
        </li>
      </ul>
    </div>
  )
}

export default RenderCustomLegend
