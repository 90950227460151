import { useTranslation } from 'react-i18next'
import EvolutionEmotionalBarChart from './Charts/EvolutionEmotionalBarChart'
import { AnalysisHistoricalResults } from '../types'
import BodyText from 'libs/shared/ui/src/lib/components/generics/body-text'
import DSTitle from 'libs/shared/ui/src/lib/components/generics/ds-title'

interface GenericChartsProps {
  handleModalToggle: () => void
  title: string
  modalLink: string
  chartTitle: string
  chartDescription: string
  chartDescription2: string
  error: unknown
  data: AnalysisHistoricalResults | null
  scoreKey: string
  lowLabel: string
  mediumLabel: string
  highLabel: string
  yAxisLabels: string[]
  scoreKey2: string
  noScoresInChart: boolean
  noScoresInChart2: boolean
}

const EmotionalCharts = ({
  handleModalToggle,
  title,
  modalLink,
  chartTitle,
  chartDescription,
  chartDescription2,
  error,
  data,
  scoreKey,
  scoreKey2,
  lowLabel,
  mediumLabel,
  highLabel,
  yAxisLabels,
  noScoresInChart,
  noScoresInChart2,
}: GenericChartsProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col w-full bg-neutral-100 rounded-lg p-6 lg:p-10 min-h-[653px] font-aeonik text-generic-neutral-900">
      <DSTitle className="mb-4 text-[#333333]">{title}</DSTitle>
      <BodyText
        className="underline mb-4 cursor-pointer"
        onClick={handleModalToggle}
      >
        {modalLink}
      </BodyText>
      <DSTitle className="my-4">{chartTitle}</DSTitle>
      <BodyText className="mb-5">{chartDescription}</BodyText>
      {error ? (
        <div className="flex justify-center items-center h-full text-center">
          <BodyText>
            {t('myEvolution.resultsDescription.fetchError1')} <br />
            {t('myEvolution.resultsDescription.fetchError2')}
          </BodyText>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {noScoresInChart ? null : (
            <EvolutionEmotionalBarChart
              data={data}
              scoreKey={scoreKey || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
          <h5 className="mb-5 text-base">{chartDescription2}</h5>
          {noScoresInChart2 ? null : (
            <EvolutionEmotionalBarChart
              data={data}
              scoreKey={scoreKey2 || ''}
              lowLabel={lowLabel}
              mediumLabel={mediumLabel}
              highLabel={highLabel}
              yAxisLabels={yAxisLabels || []}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default EmotionalCharts
