import { View } from '@react-pdf/renderer'
import { getRecommendations, tw } from '../../../utils'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { useReportPdfContext } from '../../../provider'
import { FC } from 'react'

type NutritionalObjectivesProps = {
  sectionLetter: string
}

export const NutritionalObjectives: FC<NutritionalObjectivesProps> = ({
  sectionLetter,
}) => {
  const { t, recommendations } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.objectives'

  const defaultKey = 'rec_nutritional_mediterranean_keep'

  const objectives = getRecommendations({
    recKeys: recommendations?.NUTRITIONAL,
    defaultKey,
  })

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.objectives.title', {
          letter: sectionLetter,
        })}
      >
        {objectives.sort().map((key) => {
          if (!key) return null
          const isMediterranean = key === 'rec_nutritional_mediterranean'

          return isMediterranean ? (
            <TranscriptionScriptPDF
              key={`objective-${key}`}
              script={`${TRANSLATION_BASE_KEY}.objectiveList.${key}`}
              style={tw('pt-5')}
            />
          ) : (
            <TranscriptionScriptPDF
              key={`objective-${key}`}
              script={`${TRANSLATION_BASE_KEY}.objectiveList.${defaultKey}`}
              style={tw('pt-5')}
            />
          )
        })}
      </SubSection>
    </View>
  )
}
